import React from 'react';
import {BrowserRouter,Switch,Route} from 'react-router-dom';

/* paginas */
import HomePage from './pages/home';
import NosotrosPage from './pages/nosotros';
import ContactoPage from './pages/contacto';
import DocumentacionPage from './pages/documentacion';
import SubunidadesPage from './pages/subunidades';
import NoticiasPage from './pages/noticias'

/* layouts */
import PaginaLayoutRoute from './layouts/paginaLayout';

const NoMatch = ({ location }) => {

   return <h1>La dirección {location.pathname} no fue encontrada en http://uda.dpaunt.edu.pe/</h1>     
      
}

function RouterApp() {

  return(

        <BrowserRouter>
            <Switch>

                <PaginaLayoutRoute exact path="/home" component={HomePage} />
                <PaginaLayoutRoute exact path="/" component={HomePage} />
                <PaginaLayoutRoute exact path="/nosotros" component={NosotrosPage} />
                <PaginaLayoutRoute exact path="/contacto" component={ContactoPage} />
                <PaginaLayoutRoute exact path="/documentacion" component={DocumentacionPage}/>
                <PaginaLayoutRoute exact path="/subunidades" component={SubunidadesPage}/>
                <Route exact path="/noticias/:type/:id" component={NoticiasPage} />
                <Route component={NoMatch} />
            </Switch>
        </BrowserRouter>
    
  );
}

export default RouterApp;
