import React from 'react';
import Telefono from '../assets/icon/llamada.png';
import PDFViewer from 'mgr-pdf-viewer-react';

import '../styles/documentacion.css';

class DocumentacionPage extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            "dcoumentos" : [
                { id:1, url:"https://uda.dpaunt.edu.pe/gallery/RAU_011-2021.pdf"},
                { id:2, url:"https://uda.dpaunt.edu.pe/gallery/RCU-N-382-2021-UNT.pdf"},
                { id:3, url:"https://uda.dpaunt.edu.pe/gallery/RCU-N-279-2021-UNT.pdf"},
                { id:4, url:"https://uda.dpaunt.edu.pe/gallery/RCU-N-254-2021-UNT.pdf"},
                { id:5, url:"https://uda.dpaunt.edu.pe/gallery/RCU-N-253-2021-UNT.pdf"},
                { id:6, url:"https://uda.dpaunt.edu.pe/gallery/RCU-N-164-2021-UNT.pdf"},
                { id:7, url:"https://uda.dpaunt.edu.pe/gallery/RCU-N-036-2021-UNT.pdf"}

            ],
            "documentoSeleccionado" : "https://uda.dpaunt.edu.pe/gallery/RAU_011-2021.pdf" 
        } 
    } 

    findDocuments(id){
        let documentoBuscado = this.state.dcoumentos.find(element => element.id === id)
        this.setState({
            "documentoSeleccionado" : documentoBuscado.url
        })
    }

    componentDidMount(){
        this.registrarVisita("documentación")
    }

    registrarVisita(nombre){
        var data = new FormData();
        data.append("pagina", nombre);
        fetch('https://uda.manager.dpaunt.edu.pe/API-REST/controller/visitas.php?op=agregar',
        {method: 'POST',body: data}
        ).then(res=>res.text()).then(
        (data)=>{
            console.log(data);
        },
        (error)=>{
            console.log('error');
        }
        );
    }

    render(){
        return(
            <div className='container_docs'>
                <nav style={{"--bs-breadcrumb-divider": "'>';"}} aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="/"><i className="fas fa-home"></i></a></li>
                                <li className="breadcrumb-item active" aria-current="page">Documentación</li>
                            </ol>
                        </nav>
                <div className='row'>
                    <div className='col-md-4'>
                        <h3>Resoluciones vigentes</h3>
                        <div className='docs'>
                            <a href="#" onClick={()=>this.findDocuments(1)}><i className="fas fa-bullseye"></i> RAU 011-2021-AU-UNT</a>
                        </div>
                        <div className='docs'>
                            <a href="#"  onClick={()=>this.findDocuments(2)}><i className="fas fa-bullseye"></i> RCU-N-382-2021-UNT</a>
                        </div>
                        <div className='docs'>
                            <a href="#" onClick={()=>this.findDocuments(3)}><i className="fas fa-bullseye"></i> RCU-N-279-2021-UNT</a>
                        </div>
                        <div className='docs'>
                            <a href="#" onClick={()=>this.findDocuments(4)}><i className="fas fa-bullseye"></i> RCU-N-254-2021-UNT</a>
                        </div>
                        <div className='docs'>
                            <a href="#" onClick={()=>this.findDocuments(5)}><i className="fas fa-bullseye"></i> RCU-N-253-2021-UNT</a>
                        </div>
                        <div className='docs'>
                            <a href="#" onClick={()=>this.findDocuments(6)}><i className="fas fa-bullseye"></i> RCU-N-164-2021-UNT</a>
                        </div>
                        <div className='docs'>
                            <a href="#" onClick={()=>this.findDocuments(7)}><i className="fas fa-bullseye"></i> RCU-N-036-2021-UNT</a>
                        </div>
                    </div>
                    <div className='col-md-8'>
                    <iframe src={`https://docs.google.com/gview?url=${this.state.documentoSeleccionado}&embedded=true`} style={{"width":"100%", "height":"500px"}} frameborder="0"></iframe>
                    </div>
                </div>
                
            </div>
        )
    }

}

export default DocumentacionPage;