import React from 'react';
import '../styles/card.css';
import {Link} from 'react-router-dom';

class CardComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data : props.new
        }
    }

    render() {
        return (
          /* to={`/noticias-eventos/noticias/${this.state.data.newId}`}*/
            <Link to={`/noticias/noticias/${this.state.data.newId}`} className="card">
                <div style={{backgroundImage : `url(${this.state.data.headImageUrl})`, backgroundSize: "cover"}} 
                class="card-img-top"></div>
                <div class="card-body">
                    <h5 class="card-title">{this.state.data.title.toUpperCase()}</h5>
                    <p class="card-text">{this.state.data.abstract}</p>
                    <button type="button" class="btn btn-outline-primary" >Ver más...</button>

                </div>
            </Link>
        )
    }
}

export default CardComponent;