import React from 'react';
import * as $ from 'jquery';
import {Link} from 'react-router-dom';
import imagen1 from '../assets/img/unt_img1.png';
import imagen2 from '../assets/img/unt_img2.png';
import '../styles/header.css';

class HeaderComponent extends React.Component{

    render() {
      
        return (

            <div>
                
                <div className="contenedor_header">
                    <div id="carouselExampleIndicators" className="carousel slide carousel-fade" data-ride="carousel">
                          <ol className="carousel-indicators">
                            <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                          </ol>
                          <div className="carousel-inner">
                            <div className="carousel-item active">
                              <div className="img1"> 
                                <img src={imagen1} className="d-block w-100" alt="..." />
                              </div>
                              
                            </div>
                            <div className="carousel-item">
                              <div className="img1"> 
                                <img src={imagen2} className="d-block w-100" alt="..." />
                              </div>
                              
                            </div>
                            <div className="carousel-item">
    
                              <div className="img1"> 
                                <img src={imagen1} className="d-block w-100" alt="..." />
                              </div>
    
                            </div>
                          </div>
                          <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                          </a>
                          <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                          </a>
                      </div>
    
                </div>
            </div>
               
    
    
        );
      }
    }
    

export default HeaderComponent;