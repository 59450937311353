import React from 'react'

import NavBar from '../components/navbar';
import Footer from '../components/footer';
import '../styles/noticias.css';

class NoticiasPage extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            title : this.props.match.params.type,
            dataId : this.props.match.params.id,
            data : {}
        }
    }
    
    componentDidMount(){
        let formData = new FormData()
        formData.append("idnoticia", this.props.match.params.id)
        fetch("https://uda.manager.dpaunt.edu.pe/API-REST/controller/noticia.php?op=buscar",
        {method: "POST",body: formData})
        .then(res => res.json())
        .then( response => {
            this.setState({
                data : response
            })
        })
        this.registrarVisita("noticias")
    }

    registrarVisita(nombre){
        var data = new FormData();
        data.append("pagina", nombre);
        fetch('https://uda.manager.dpaunt.edu.pe/API-REST/controller/visitas.php?op=agregar',
        {method: 'POST',body: data}
        ).then(res=>res.text()).then(
        (data)=>{
            console.log(data);
        },
        (error)=>{
            console.log('error');
        }
        );
    }
    
    render(){
        return(
            <div>
                <NavBar/>
                <div className="container">
                    {/*  breadcrumbs de localizacion */}
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/home">Home</a></li>
                            <li className="breadcrumb-item active" aria-current="page">{this.state.title.toUpperCase()}</li>
                        </ol>
                    </nav>
                    {/*  Fin breadcrumbs de localizacion */}
                    <div className="title-main">
                        <h3>{this.state.data.title}</h3>
                    </div>

                    {
                        this.state.title == "eventos" ? <span className="badge badge-danger" style={{fontSize: "17px"}}><i className="fas fa-calendar"></i> {this.state.data.date}</span> : null
                    }
                    
                    <div className="contents" dangerouslySetInnerHTML={{ __html: this.state.data.content }}>
                    </div>
                  

                </div>
                <Footer/>
                
            </div>
        )
    }

}

export default NoticiasPage;