import React from 'react';
import HeaderComponent from '../components/header'
import Reforma_curricular from '../assets/img/reforma_curricular.png';
import Repositorio from '../assets/img/repositorio.png';
import Certificados from '../assets/img/certificados.png';
import Cursos_mocc from '../assets/img/cursos_mooc.png';


import { ReactPhotoCollage } from "react-photo-collage";
import CardComponent from '../components/card'

import '../styles/home.css';

class HomePage extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            titulo : "Pagina home",
            news : [],
            pageNews : 0,
            pageEvents : 0,
            /*
            news : [
                {
                newId : "1",
                headImageUrl : "https://unitru.edu.pe/webfiles//ProyeccionSocial/2020/12/224_ProyeccionSocial__18122020033513.jpg",
                title : "UNT REALIZARÁ CAMPAÑA GRATUITA DE SALUD EN CHICLÍN",
                abstract : "La UNT a través de la Dirección de Bienestar Universitario en coordinación con el Centro de Salud de Chicama...",
                },
                {
                 newId : "2",
                headImageUrl : "https://unitru.edu.pe/webfiles//ProyeccionSocial/2021/8/0_ProyeccionSocial__12082021083609.JPG",
                title : "UNT CREA LA PRIMERA BRIGADA UNIVERSITARIA EN DESASTRES NATURALES EN LA REGIÓN",
                abstract : "El monitoreo del riesgo y la acción temprana son las claves para reducir los enormes daños y las millonarias... "
                },
                {
                newId : "1",
                headImageUrl : "https://unitru.edu.pe/webfiles//ProyeccionSocial/2020/12/224_ProyeccionSocial__18122020033513.jpg",
                title : "UNT REALIZARÁ CAMPAÑA GRATUITA DE SALUD EN CHICLÍN",
                abstract : "La UNT a través de la Dirección de Bienestar Universitario en coordinación con el Centro de Salud de Chicama...",
                }
             ],*/
             settingsCollage : ""
        }
        
    } 

    componentDidMount(){
        this.getNews()
        this.registrarVisita("home")
        this.obtenerFotos()
    }
    obtenerFotos(){
        fetch('https://uda.manager.dpaunt.edu.pe/API-REST/controller/evento.php?op=listar',
        {method:'GET'}) 
        .then(res => res.text())
        .then(data => {
            data = JSON.parse(data)
            let array = data.map(element => { return {"source":element[3]}})
            this.setState({
                "settingsCollage" : {
                    width: '100%',
                    height: ['350px', '350px'],
                    layout: array.length <=3 ? [3] : [3,4],
                    photos: array,
                    showNumOfRemainingPhotos: true
                }
                
            })
            console.log(this.state.settingsCollage)
        })

    }

    registrarVisita(nombre){
        var data = new FormData();
        data.append("pagina", nombre);
        fetch('https://uda.manager.dpaunt.edu.pe/API-REST/controller/visitas.php?op=agregar',
        {method: 'POST',body: data}
        ).then(res=>res.text()).then(
        (data)=>{
            console.log(data);
        },
        (error)=>{
            console.log('error');
        }
        );
    }
    
    getNews(){
        let formData = new FormData()
        formData.append("page", this.state.pageNews)
        fetch("https://uda.manager.dpaunt.edu.pe/API-REST/controller/noticia.php?op=listar-noticias-web",
        {method: "POST", body : formData})
        .then(res => res.json())
        .then( response => {
            this.setState({pageNews : this.state.pageNews + 4})
            let array_join = this.state.news.concat(response)
            this.setState({                
                "news" : array_join
            })
        })
    }
     
    render(){
        return(

            <div>
                <HeaderComponent/>
                
                <div className="container-home">
                    <div className="row home_herramientas">
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-6">
                            <div className="h_herramienta">
                                <a href="https://curriculo.dpaunt.edu.pe/login" target="_blank"><img className="footer_img_1" src={Reforma_curricular} alt="..." />
                                    <h5>Reforma Curricular</h5> 
                             
                                </a>
                               
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-6">
                            <div className="h_herramienta">
                                <a href="https://repositorio.dpaunt.edu.pe/" target="_blank"><img className="footer_img_1" src={Repositorio} alt="..." />
                                    <h5>Repositorio Virtual</h5>
                                
                                </a>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-6">
                            <div className="h_herramienta">
                                <a href="https://certificado.dpaunt.edu.pe/login" target="_blank"><img className="footer_img_1" src={Certificados} alt="..." />
                                    <h5>Certificaciones</h5>
                                   
                                </a>
                                
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-6">
                            <div className="h_herramienta">
                                <a href="https://aulavirtual2.unitru.edu.pe/login/index.php" target="_blank"><img className="footer_img_1" src={Cursos_mocc} alt="..." />
                                    <h5>Cursos MOOC</h5>
                                  
                                </a>
                                
                            </div>
                        </div>
                       
                       
                        
                    </div>
                    <div className="home_noticias">
                        <h3>NOTICIAS</h3>
                        <div className="row" style={{width:"100%"}}>
                            {
                                this.state.news.length == 0 ?
                                <div className="row" style={{width: "100%"}}><p style={{textAlign: 'center', width: "100%"}}>No hay noticias disponibles</p></div>
                                : this.state.news.map( item => {
                                    return(
                                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                                            <CardComponent new={item} />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="home_collage">
                        <h3>GALERIA</h3>

                        {
                            this.state.settingsCollage != "" ? 
                                <ReactPhotoCollage {...this.state.settingsCollage} />
                            : null
                        }
                        
                        
                    </div>
                    


                </div>

            </div>
            
        )
    }

}

export default HomePage;