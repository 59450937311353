import React from 'react';

import logo from '../assets/img/UDA_blanco.png';
import mensaje from '../assets/img/mensaje.png';
import reclamaciones from '../assets/img/libro_de_reclamaciones.png';

import '../styles/footer.css';

class Footer extends React.Component{
render (){
    return(
     <div className="container_footer"> 
            <div className="row container_footer_top">
                <div className="col-md-4">
                    <div className="footer_top_1">
                        <h5>SOBRE ESTE SITIO</h5>
                        <br></br>
                        <a href="#">Mapa del sitio<i className="fas fa-angle-right"></i></a>
                        <a href="#">Plataforma virtual<i className="fas fa-angle-right"></i> </a>
                        <a href="#">Boletines <i className="fas fa-angle-right"></i></a>
                        <a href="">Reforma curricular <i className="fas fa-angle-right"></i></a>
                        <p></p>

                    </div>
                    
                </div>
                <div className="col-md-4">
                    <div className="footer_top_2">
                        <h5>CONTACTO</h5>
                        <span>Teléfono: (044) 474832 <br></br> Correo: uda@unitru.edu.pe</span>

                        <div clasName="footer_imagenes">
                                <a href="#"><img className="footer_img_1" src={mensaje} alt="..." /></a>
                                <a href="https://form.jotform.com/210943266995063"  target="_blank"><img className="footer_img_2" src={reclamaciones} alt="..." /></a>
                        </div>

                    </div>
                </div>
                <div className="col-md-4">
                    <div className="footer_top_3">
                        <h5>UBICACIÓN</h5>
                        <span>Oficina Central <br></br> Jr. Zepita 482, Centro Histórico 13001 <br></br> Trujillo, Perú</span>
                        <br></br><br></br>
                        <p>Síguenos</p>
                        <div className="footer_rrss">
                            <div className="enlace_rrss">
                                <a><i class="fab fa-facebook-f"></i></a>
                            </div>
                            <div className="enlace_rrss">
                                <a><i class="fab fa-youtube"></i></a>
                            </div>
                            <div className="enlace_rrss">
                                <a><i class="fab fa-linkedin-in"></i></a>
                            </div>
                            <div className="enlace_rrss">
                                <a><i class="fab fa-twitter"></i></a>
                            </div>
                            
                        </div>

                    </div>
                </div>
            </div>
            <div className="container_footer_bottom">
                <p>Copyright © Unidad de Desarrollo Académico UNT 2021. All rights reserved.</p>
            </div>
    
     </div>
    )
}

}

export default Footer;