import React from 'react';
import * as $ from 'jquery';
import {Link} from 'react-router-dom';

import logo from '../assets/img/UDA_color.png';
import '../styles/navbar.css';

class NavBar extends React.Component{
    
    constructor(props){
        super(props);
        this.state = {value: ''};
        $(document).ready(()=>{
            $(window).scroll(function() {
                if ($(window).scrollTop() > 10) {
                  $('.logo_nav').stop().animate({'width':'130px'},300);
                } else {
                  //$('ul li .nav-link').css('color','#BF8F00');
                    $('.logo_nav').stop().animate({'width':'200px'});
                }
            });
        })
    }
    render(){
        return(
            <div>
                <div className="contenedor_navbar">

                    <div className="barra_top">
                        <div class="telefono">
                            <label><i class="fa fa-phone"></i> Teléfono:</label> (044) 474832
                        </div>
                        <div class="correo_institucional">
                            <label> <i class="far fa-envelope-open"></i> Correo:</label> uda@unitru.edu.pe
                        </div>
                    </div>
                    <nav className="navbar navbar-expand-lg  navbar-light ">
                        <div className="logo_uda">
                            <a className="" href="/home"><img src={logo} className="logo_nav" /></a>
                        </div>
                        {/*Btn de menu movil*/}
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                
                            <ul className="navbar-nav mr-auto">
                                <li className="nav-item active">
                                    <Link to="/" className="nav-link"><i className="fas fa-home"></i> Inicio <span className="sr-only">(current)</span></Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/nosotros" className="nav-link"><i className="fab fa-sketch"></i> Conócenos</Link>
                                </li>                                
                        
                                <li className="nav-item">
                                    <Link to="/subunidades" className="nav-link"><i className="fas fa-sitemap"></i> Subunidades</Link>
                                   
                                </li>

                                <li className="nav-item">
                                    <Link to="/documentacion" className="nav-link"><i class="fas fa-file"></i> Documentación</Link>
                                </li>

                                <li className="nav-item">
                                    <Link to="/contacto" className="nav-link"><i className="fas fa-chalkboard-teacher"></i> Contacto</Link>
                                </li>
                            </ul>

                        {/*boton de inicio de sesion 
                        <div>
                                <a name="" id="" class="btn btn-primary" href="#" role="button">Iniciar Sesión</a>                
                        </div>*/}
                        </div>
            
                      
                    </nav>
                    {/*<div className="barra_rrss">
                    
                    <div className="rrss_nav">
                            <div className="enlace_rrss">
                            <i className="fab fa-facebook-square"></i>
                            </div>
                            <div className="enlace_rrss">
                            <i className="fab fa-instagram"></i>
                            </div>
                            
                            <div className="enlace_rrss">
                            <i className="fab fa-linkedin"></i>
                            </div>    
                        </div> 
                        
                    </div>*/}
                    
                </div>
                <div className="space-bar">

                </div>
            </div>
        )
    }
 }

 export default NavBar;