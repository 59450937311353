import React, { Component } from 'react';
        import OrgChart from '@balkangraph/orgchart.js';

        export default class extends Component {

            constructor(props) {
                super(props);
                this.divRef = React.createRef();
            }

            shouldComponentUpdate() {
                return false;
            }

            componentDidMount() {
                OrgChart.templates.ana.field_0  = '<text width="230" style="font-size: 15px; font-weight: bold;" fill="#12377B" x="125" y="95" text-anchor="middle" class="field_0">{val}</text>';
                OrgChart.templates.ana.field_1 = 
                '<text data-width="130" data-text-overflow="multiline" style="font-size: 13px;" fill="#1B1811" x="230" y="30" text-anchor="end" class="field_1">{val}</text>';

                this.chart = new OrgChart (this.divRef.current , {
                    editForm: {readOnly: true},
                    nodes: this.props.nodes,
                    nodeBinding: {
                        field_0: "Nombre", 
                        field_1: "Cargo",
                        img_0: "img",
                    },
                    layout:OrgChart.tree,
                    mouseScrool : false,
                    enableSearch : false,
                    tags: {
                        "subLevels0": {
                            subLevels: 0
                        },
                        "subLevels1": {
                            subLevels: 1
                        },
                        "subLevels2": {
                            subLevels: 2
                        },
                        "subLevels3": {
                            subLevels: 3
                        }
                    }

                });
            }

            render() {
                return (
                    <div id="tree" ref={this.divRef}></div>
                );
            }
        }
