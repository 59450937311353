import React from 'react';
import Portada from '../assets/img/portada_nosotros.png';
import OrgChart from '../components/myTree';
import Mision from '../assets/icon/mision.png';
import Vision from '../assets/icon/vision.png';
import Valor from '../assets/icon/valor.png';
import Jefe_UDA from '../assets/img/jefe_uda.png';
import Vineta from '../assets/icon/vineta.png';



import '../styles/nosotros.css';

class NosotrosPage extends React.Component{
    
    constructor(props){
        super(props)
        this.state = {
        } 
    } 


    componentDidMount(){
        this.registrarVisita("nosotros")
    }

    registrarVisita(nombre){
        var data = new FormData();
        data.append("pagina", nombre);
        fetch('https://uda.manager.dpaunt.edu.pe/API-REST/controller/visitas.php?op=agregar',
        {method: 'POST',body: data}
        ).then(res=>res.text()).then(
        (data)=>{
            console.log(data);
        },
        (error)=>{
            console.log('error');
        }
        );
    }

    render(){


        return(
            <div>
                <div className="nosotros_portada">
                    <img className="footer_img_1" src={Portada} alt="..." />
                </div>
                <div className="container_nosotros">
                    <nav style={{"--bs-breadcrumb-divider": "'>';"}} aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/"><i className="fas fa-home"></i></a></li>
                            <li className="breadcrumb-item active" aria-current="page">Conócenos</li>
                        </ol>
                    </nav>
                    <div className='row mvv'>
                        <div className='col-md-4'>
                            <div className="card-img-top">
                                <img src="..." className="card-img-top" src={Mision} alt="..."/>
                                <div className="card-body">
                                    <h4>Misión</h4>
                                    <p class="card-text">Gestionar con ímpetu la normatividad, evaluación de los aprendizajes y la innovación académica para mejorar la calidad de los servicios académicos frente a los desafios institucionales actuales.</p>
                                </div>
                            </div>
                         </div>
                        <div className='col-md-4'>
                            <div className="card-img-top">
                                <img src="..." className="card-img-top" src={Vision} alt="..."/>
                                <div className="card-body">
                                    <h4>Visión</h4>
                                    <p className="card-text">Al 2026, la Unidad de Desarrollo Académico fortalecerá su relevancia en las actividades, documentos y las propuestas innovadoras para la oportuna toma de decisiones de interés académico de la UNT.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className="card-img-top">
                                <img src="..." className="card-img-top" src={Valor} alt="..."/>
                                <div className="card-body">
                                    <h4>Valores</h4>
                                    <p className="card-text">
                                        <ul>
                                            <li>Excelencia</li>
                                            <li>Compromiso</li>
                                            <li>Innovación</li>
                                            <li>Trabajo en equipo</li>
                                            <li>Equidad</li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                        </div>        
                    </div>
                    <div className='row jefe_uda'>
                        <div className='col-md-4 img_jefe'>
                            <img src="..."  src={Jefe_UDA} alt="..."/>
                        </div>
                        <div className='col-md-8 mensaje_jefe'>
                            <p>Mi compromiso como líder de este equipo es ejecutar y 
                                promover el cumplimiento de la normatividad académica
                                por medio de la innovación tecnológica para el
                                fortalecimiento de la calidad educativa de la
                                Universidad Nacional de Trujillo. Y así, seguir resaltando como una de las mejores
                                universidades de nuestro país.</p>
                            <h6>José Rodríguez Llajaruna.</h6>
                            <span>Jefe de la Unidad de Desarrollo Académico - UDA</span>
                        </div>
                    </div>
                    {/* inicio del funciones */}
                    <div className='container_functions'>
                       <h3>FUNCIONES</h3>
                        <div style={{width:'100%'}}>
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item"><i class="far fa-circle"></i> <p>Planificar, organizar, ejecutar y evaluar las actividades de gestión e innovación académica y evaluación de los procesos académico-administrativos, en pregrado, posgrado y segunda especialidad profesional.</p></li>
                                <li class="list-group-item"><i class="far fa-circle"></i> <p>Ejecutar y promover el cumplimiento de las políticas académicas de la Universidad, atendiendo a los requerimientos de las diferentes unidades orgánicas.</p></li>
                                <li class="list-group-item"><i class="far fa-circle"></i> <p>Ejecutar y promover el cumplimiento de la normatividad académica Universitaria, local y nacional.</p></li>
                                <li class="list-group-item"><i class="far fa-circle"></i> <p>Elaborar, implementar y actualizar los reglamentos, directivas, instructivos académicos, manuales, documentos de gestión académica, en concordancia con el modelo educativo UNT y normas legales vigentes.</p></li>
                                <li class="list-group-item"><i class="far fa-circle"></i> <p>Implementar, ejecutar y mantener el programa interno de fortalecimiento docente, de acuerdo a las nuevas tendencias, bajo modalidades curriculares vigentes.</p></li>
                                <li class="list-group-item"><i class="far fa-circle"></i> <p>Elaborar informes, reportes y boletines estadísticos relacionados con la gestión curricular y la evaluación académica.</p></li>
                                <li class="list-group-item"><i class="far fa-circle"></i> <p>Elaborar, ejecutar, monitorear el plan operativo y el presupuesto de la Unidad, evaluando su gestión de conformidad con las políticas institucionales establecidas por los órganos correspondientes y presentando los informes de gestión pertinentes.</p></li>
                                <li class="list-group-item"><i class="far fa-circle"></i> <p>Otras funciones de su competencia establecidas por normas y disposiciones legales vigentes.</p></li>
                            </ul>
                        </div>
                    </div>

                    {/* inicio del organigrama */}
                    <div className='container_orgchart'>
                        <h3>ORGANIGRAMA</h3>
                        <div  style={{width:'100%'}}>
                            
                            <OrgChart nodes={[
                                { id: 1, Nombre: "Lic. José Rodriguez Llajaruna", Cargo: "Jefe de Unidad de Desarrollo Académico", img: "https://uda.dpaunt.edu.pe/gallery/Equipo_UDA/jose_llajaruna.png" ,Email:"jrodriguezll@unitru.edu.pe" },
                                { id: 2, pid: 1, Nombre: "Ing. Everett Murga Milla", Cargo: "Jefe de Gestión e Innovación Curricular", img: "https://uda.dpaunt.edu.pe/gallery/Equipo_UDA/everett_murga.png" ,email:"emurga@unitru.edu.pe" },
                                { id: 3, pid:1, Nombre: "Estela Rodriguez Otoya", Cargo: "Secretaria", img: "https://uda.dpaunt.edu.pe/gallery/Equipo_UDA/estela_rodriguez.png",email:"erodriguezo@unitru.edu.pe"},
                                { id: 4, pid: 1, Nombre:  "Lic. Luis Urquiza Sánchez", Cargo: "Jefe de Evaluación de los Procesos Académicos-Administ.", img: "https://uda.dpaunt.edu.pe/gallery/Equipo_UDA/luis_urquiza.png",email:"lurquiza@unitru.edu.pe"  },
                                { id: 5, pid: 2, Nombre: "Ing. Sussy Carrión Aguilar", Cargo: "Asistente de Informática", img: "https://uda.dpaunt.edu.pe/gallery/Equipo_UDA/sussy_carrion.png",email:"scarrion@unitru.edu.pe"  },
                                { id: 6, pid: 3, Nombre: "Bach. Rosimar Veneros Heros", Cargo: "Soporte Administrativo", img: "https://uda.dpaunt.edu.pe/gallery/Equipo_UDA/rosi_venerosh.png",email:"rveneros@unitru.edu.pe" },
                                { id: 7, pid:3, Nombre: "Bach. Athina Cunia Romero", Cargo: "Soporte Técnico", img: "https://uda.dpaunt.edu.pe/gallery/Equipo_UDA/athina_cunia.png",email:"acunia@unitru.edu.pe" },
                                { id: 8, pid: 3, Nombre: "Lic. Ricardo Azabache Abanto", Cargo: "Soporte Administrativo", img: "https://uda.dpaunt.edu.pe/gallery/Equipo_UDA/ricardo_azabache.png",email:"razabache@unitru.edu.pe"  },
                                { id: 9, pid: 4, Nombre: "Ing. Daniel Asencio Ortiz", Cargo: "Especialista en Sistemas", img: "https://uda.dpaunt.edu.pe/gallery/Equipo_UDA/daniel_asencio.png",email:"dasencio@unitru.edu.pe"  }
                            ]}/>
                        </div>
                    </div>

                </div>
                
                
                
                
            </div>
                
        )
    }

}

export default NosotrosPage;