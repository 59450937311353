import React from 'react';
import '../styles/subunidades.css'

class SubunidadesPage extends React.Component{

    constructor(props){
        super(props)
        this.state = {
        } 
    } 


    componentDidMount(){
        this.registrarVisita("subunidades")
    }

    registrarVisita(nombre){
        var data = new FormData();
        data.append("pagina", nombre);
        fetch('https://uda.manager.dpaunt.edu.pe/API-REST/controller/visitas.php?op=agregar',
        {method: 'POST',body: data}
        ).then(res=>res.text()).then(
        (data)=>{
            console.log(data);
        },
        (error)=>{
            console.log('error');
        }
        );
    }

    render(){
        return(
            <div className='container_subunidades'>
            
                <div className='separador'>
                    <div className='contenedor left'>
                        <p><i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i></p>
                        <p><i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i></p>
                        <p><i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i></p>
                        <p><i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i></p>
                        <p><i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i></p>
                    </div>

                    <div className='contenedor right'>
                        <p><i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i></p>
                        <p><i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i></p>
                        <p><i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i></p>
                        <p><i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i></p>
                        <p><i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i></p>
                    </div>
                </div>
                
                {/** sub unidad 1*/}
                <div className='row'>

                    <div className='row portada'>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 img-portada"></div>

                        <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 nombre-portada'>
                            <div className='contenedor-descripcion'>
                                <h2>
                                    Subunidad de  <br/> <label>Gestión e Innovación Curricular</label>
                                </h2>
                                <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam blandit, orci nec convallis ornare, nibh est sodales tellus, et egestas risus justo non magna. Nunc lobortis ipsum nec ornare fermentum. Ut et dolor ut ligula euismod tempus. Donec imperdiet condimentum nunc rhoncus finibus. Cras tempus convallis libero nec ultrices. Ut leo enim, consectetur sed varius ut, viverra blandit sem.
                                </p>
                            </div>
                        </div>
                    </div>

                    {/* inicio del funciones */}
                    <div className='container_functions'>
                        <h3>FUNCIONES</h3>
                        <div style={{width:'100%'}}>
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item"><i class="far fa-circle"></i> <p>Participar en la formulación de propuestas de innovación y lineamientos de política de gestión y normatividad académica.</p></li>
                                <li class="list-group-item"><i class="far fa-circle"></i> <p>Coordinar la gestión e innovación curricular de pregrado, segunda especialidad profesional, educación a distancia y posgrado se formulen y ejecuten en función de la Ley Universitaria, Estatuto UNT, Plan Estratégico Institucional (PEI), Plan Operativo Institucional (POI), Modelo Educativo (MOEDUNT), Modelo de Calidad, disposiciones de la SUNEDU y MINEDU.</p></li>
                                <li class="list-group-item"><i class="far fa-circle"></i> <p>Proponer, planificar y ejecutar programas de capacitación académica para fortalecer las competencias didácticas y pedagógicas de los docentes universitarios para su mejor desempeño.</p></li>
                                <li class="list-group-item"><i class="far fa-circle"></i> <p>Emitir informes técnicos, reportes y publicaciones sobre gestión curricular, movilidad estudiantil, conversión de notas a sistemas de calificación de universidades extranjeras.</p></li>
                                <li class="list-group-item"><i class="far fa-circle"></i> <p>Otras funciones de su competencia y las establecidas por normas y dispositivos legales vigentes.</p></li>
                            </ul>
                        </div>
                    </div>

                </div>

                <div className='separador'>
                    <div className='contenedor'>
                        <p><i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i></p>
                        <p><i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i></p>
                        <p><i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i></p>
                        <p><i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i></p>
                        <p><i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i></p>
                    </div>
                </div>

                {/** sub unidad 2*/}
                <div className='row'>

                    <div className='row portada'>
                        <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 nombre-portada'>
                            <div className='contenedor-descripcion2'>
                                <h2>
                                    Subunidad de  <br/> <label>Evaluación de los Procesos Académico-Administrativos</label>
                                </h2>
                                <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam blandit, orci nec convallis ornare, nibh est sodales tellus, et egestas risus justo non magna. Nunc lobortis ipsum nec ornare fermentum. Ut et dolor ut ligula euismod tempus. Donec imperdiet condimentum nunc rhoncus finibus. Cras tempus convallis.
                                </p>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 img-portada2"></div>
                    </div>

                    {/* inicio del funciones */}
                    <div className='container_functions'>
                        <h3>FUNCIONES</h3>
                        <div style={{width:'100%'}}>
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item"><i class="far fa-circle"></i> <p>Atender consultas sobre procesos de evaluación académico-administrativos de la sede central y filiales de la Universidad; modalidades: presencial, semi presencial y no presencial (a distancia).</p></li>
                                <li class="list-group-item"><i class="far fa-circle"></i> <p>Participar en actividades de evaluación de procesos de enseñanza aprendizaje, documentos técnicos – pedagógicos, currículos, sílabos, carpeta pedagógica (presencial) y portafolio digital (semi presencial y no presencial); en función de los niveles educativos, normatividad académica local y nacional.</p></li>
                                <li class="list-group-item"><i class="far fa-circle"></i> <p>Organizar y registrar los procesos de capacitación académica en pro de la mejora del desempeño docente.</p></li>
                                <li class="list-group-item"><i class="far fa-circle"></i> <p>Orientar a docentes y estudiantes en el cumplimiento de normas, reglamentos, directivas sobre procesos de enseñanza – aprendizaje.</p></li>
                                <li class="list-group-item"><i class="far fa-circle"></i> <p>Otras funciones de su competencia y las establecidas por normas y dispositivos legales vigentes.</p></li>
                            </ul>
                        </div>
                    </div>

                </div>


                <div className='separador'>
                    <div className='contenedor left'>
                        <p><i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i></p>
                        <p><i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i></p>
                        <p><i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i></p>
                        <p><i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i></p>
                        <p><i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i></p>
                    </div>

                    <div className='contenedor right'>
                        <p><i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i></p>
                        <p><i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i></p>
                        <p><i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i></p>
                        <p><i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i></p>
                        <p><i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i> <i class="fas fa-circle"></i></p>
                    </div>
                </div>

            </div>
                
        )
    }

}

export default SubunidadesPage