import React from 'react';
import Telefono from '../assets/icon/llamada.png';
import Correo from '../assets/icon/correo.png'
import Horario from '../assets/icon/reloj.png'
import Oficina from '../assets/icon/mapa.png'
import Portada from '../assets/img/portada_contacto.png';

import '../styles/contacto.css';

class ContactoPage extends React.Component{

    constructor(props){
        super(props)
        this.state = {
        } 
    } 


    componentDidMount(){
        this.registrarVisita("contacto")
    }

    registrarVisita(nombre){
        var data = new FormData();
        data.append("pagina", nombre);
        fetch('https://uda.manager.dpaunt.edu.pe/API-REST/controller/visitas.php?op=agregar',
        {method: 'POST',body: data}
        ).then(res=>res.text()).then(
        (data)=>{
            console.log(data);
        },
        (error)=>{
            console.log('error');
        }
        );
    }



    render(){
        return(
            <div>
                <div className='container_contacto'>
                    <div className="contacto_portada">
                        <img  src={Portada} alt="..." />
                    </div>
                    <h4>CONTÁCTANOS</h4>
                    <div className='row contacto_bloque'>
                        <div className='col-md-6 bloque_izq'>
                            <div className='row contacto_horario'>
                                <div className='col-md-2 contacto_icon'>
                                    <img src="..."  src={Horario} alt="..."/>
                                </div>
                                <div className='col-md-10 horario_text'>
                                    <h6>Horario de Atención</h6>
                                    <p>8:00 am - 14:45 pm Lunes a Viernes</p>
                                </div> 
                            </div>
                        </div>
                        <div className='col-md-6 bloque_der'>
                            <div className='row contacto_direccion'>
                                <div className='col-md-2 contacto_icon'>
                                    <img src="..."  src={Oficina} alt="..."/>
                                </div>
                                <div className='col-md-10 direccion_text'>
                                    <h6>Oficina Central</h6>
                                    <p>Jr. Zepita 482, Centro Histórico 13001</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row contacto_bloque'>
                        <div className='col-md-6 bloque_izq'>
                            <div className='row contacto_telefono'>
                                <div className='col-md-2 contacto_icon'>
                                    <img src="..."  src={Telefono} alt="..."/>
                                </div>
                                <div className='col-md-10 telefono_text'>
                                    <h6>Teléfonos</h6>
                                    <p>(044) 474832</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6 bloque_der'>
                            <div className='row contacto_correo'>
                                <div className='col-md-2 contacto_icon'>
                                    <img src="..."  src={Correo} alt="..."/>
                                </div>
                                <div className='col-md-10 correo_text'>
                                    <h6>Correo Institucional</h6>
                                    <p>uda@unitru.edu.pe</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>  
                <div className='contacto_mapa'>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d246.87009243954301!2d-79.03064012287484!3d-8.10947785165799!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x91ad3d84b5467591%3A0x9ac48dda636e1983!2sJir%C3%B3n%20Zepita%20482%2C%20Trujillo%2013001!5e0!3m2!1ses!2spe!4v1639960723254!5m2!1ses!2spe" style={{width:'100%', height:'450px'}} ></iframe>
                </div>
            </div>
        )
    }

}

export default ContactoPage;